import { MenuBook, RestaurantMenu } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";
import Stars from "../../SVG/Banner/stars.svg";
import Pyszne from "../../SVG/Banner/pyszne.svg";
import Glovo from "../../SVG/Banner/glovo.svg";
import Uber from "../../SVG/Banner/uber.svg";
import { Link } from "react-router-dom";

const MainDiv = styled.div`
	position: relative;
	width: 100%;
	height: 640px;
	overflow: hidden;

	@media (max-width: 639px) {
		height: 382px;
	}
`;

const VideoWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}
`;
const TextDiv = styled.div`
	max-width: 700px;
	@media (max-width: 1024px) {
		max-width: 600px;
	}
	@media (max-width: 640px) {
		max-width: 400px;
	}
`;
const Title = styled.span`
	font-size: 70px;
	color: white;
	font-weight: 500;
	/* font-style: italic; */
	text-align: center;
	font-family: Dancing Script;

	@media (max-width: 1024px) {
		font-size: 60px;
	}
	@media (max-width: 640px) {
		font-size: 40px;
	}
`;
const Number = styled.span`
	font-size: 50px;
	font-weight: 700;
`;
const NumberDesc = styled.span`
	font-size: 15px;
	color: white;
	font-weight: 200;
`;

function Banner({ t, isUnder640px }) {
	return (
		<MainDiv>
			<VideoWrapper>
				<video autoPlay loop muted playsInline>
					<source src="/la_gondola.mp4" type="video/mp4" />
				</video>
			</VideoWrapper>
			<div
				className="flex flex-col items-center py-12 px-4"
				style={{ position: "relative", zIndex: 1 }}
			>
				<TextDiv className="flex flex-col items-center">
					<Title>{t("home.banner.title")}</Title>
					{/* <DescDiv>
						<Desc>{t("home.banner.desc")}</Desc>
					</DescDiv> */}
				</TextDiv>
				<div className="flex sm:flex-row flex-col sm:gap-12 gap-10 sm:mt-12 mt-8">
					<div>
						<Link to="/online">
							<button type="button" className="filledButton">
								<div className="flex items-center gap-3">
									<RestaurantMenu
										style={{ fontSize: "24px", color: "white" }}
									/>
									<span className="filledButtonText">
										{t("home.banner.button1")}
									</span>
								</div>
							</button>
						</Link>
					</div>
					<div>
						<Link to="/menu">
							<button type="button" className="filledButton">
								<div className="flex items-center gap-3">
									<MenuBook style={{ fontSize: "24px", color: "white" }} />
									<span className="filledButtonText">
										{t("home.banner.button2")}
									</span>
								</div>
							</button>
						</Link>
					</div>
				</div>
				{isUnder640px === false && (
					<div className="flex flex-col items-center justify-center mt-6">
						<Number className="text-orange">4,8</Number>
						<img src={Stars} alt="Gwiazdki" />
						<NumberDesc className="mt-1">
							{t("home.banner.starText")}
						</NumberDesc>
						<div className="flex flex-col sm:flex-row sm:gap-12 gap-6 mt-8 sm:mt-4">
							<Link to="/online">
								<img className="cursor-pointer" src={Pyszne} alt="Pyszne" />
							</Link>
							<Link to="/online">
								<img className="cursor-pointer" src={Glovo} alt="Glovo" />
							</Link>
							<Link to="/online">
								<img className="cursor-pointer" src={Uber} alt="Uber" />
							</Link>
						</div>
					</div>
				)}
			</div>
		</MainDiv>
	);
}

export default Banner;
